<template>
  <div class="scan">
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="6" class="mt-5">
        <v-app-bar app :color="bgColor" elevation="0">
          <v-btn icon color="#fff" @click="$emit('close')">
            <v-icon medium>mdi-arrow-left</v-icon>
          </v-btn>

          <div class="logo" @click="toPage">
            <img src="@/assets/logo2.png" class="img-logo">
          </div>
        </v-app-bar>
        <v-card class="py-4 d-flex justify-center" elevation="0">
          <div class="fullscreen">
            <qrcode-stream :camera="camera" :track="selected.value" @decode="onDecode" @init="onInit" />
          </div>
          <img type="image/webp" src="@/assets/scan-bg.webp" alt="scan-bg" class="scan-bg" width="100%" height="100vh">
        </v-card>
      </v-col>
    </v-row>

    <v-btn fab x-large class="s-camera" :color="bgColor">
      <v-icon color="#fff" large>
        mdi-camera
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import { mapState } from 'vuex'

export default {
  name: 'Scan',
  components: { QrcodeStream },
  computed: {
    ...mapState([
      'bgColor'
    ])
  },
  data () {
    return {
      selected: { text: "bounding box", value: this.paintBoundingBox },
      result: null,
      camera: 'auto',
      showScanConfirmation: false
    }

  },
  methods: {
    toPage () {
      this.$emit('close')
      if (this.$route.name !== 'Home') {
        this.$router.push({'name' : 'Home'})
      }
    },
    paintBoundingBox (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox: { x, y, width, height } } = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
      }
    },
    async onInit (promise) {
      try {
        await promise
      } catch (e) {
        this.onCameraError(e)
        console.error(e)
      } finally {
        this.showScanConfirmation = this.camera === "off"
      }
    },
    onCameraError (error) {
      let message = ''
      if (error.name === 'NotAllowedError') {
        // message = 'user denied camera access permisson'
        message = 'Pastikan browser Anda dapat mengkases kamera. Ubah izin browser untuk mengakses kamera, kemudian refresh halaman ini.'
      } else if (error.name === 'NotFoundError') {
        message = 'NotFoundError - no suitable camera device installed.'
      } else if (error.name === 'NotSupportedError') {
        message = 'NotSupportedError - page is not served over HTTPS (or localhost).'
      } else if (error.name === 'NotReadableError') {
        message = 'NotReadableError - maybe camera is already in use.'
      } else if (error.name === 'OverconstrainedError') {
        message = 'OverconstrainedError - did you requested the front camera although there is none?.'
      } else if (error.name === 'StreamApiNotSupportedError') {
        message = 'StreamApiNotSupportedError - browser seems to be lacking features.'
      }

      this.$store.commit('setSnackbar', true)
      this.$store.commit('setText', message)
    },
    async onDecode (content) {
      this.result = content

      await this.timeout(500)
      this.pause()
      this.validateCode(content)

      // await this.timeout(500)
      // this.unpause()
    },
    unpause () {
      this.camera = 'auto'
    },
    pause () {
      this.camera = 'off'
    },
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    validateCode (val) {
      this.$emit('close')
      setTimeout(() => {
        window.location.href = val
      })
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
.scan {
  background-color: #fff;
  width: 100%;
  height: 100vh;
}

.s-camera {
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

.fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.scan-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
}

.logo {
  position: fixed;
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 20;
}

.img-logo {
  width: 70%;
  max-width: 50px;
  cursor: pointer;
}
</style>
