<template>
  <div>
    <v-app-bar app :color="bgColor" elevation="0" class="appbar-custom elevation-0">
      <v-app-bar-nav-icon v-if="!(isMemberPage && isMobile)"
        :color="headerColor"
        @click="showHideMenu()">
      </v-app-bar-nav-icon>

      <v-btn v-if="isMobile && isMemberPage" icon :color="headerColor" @click="back()">
        <v-icon medium>mdi-arrow-left</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <!-- <Search /> -->

      <!-- <v-btn v-if="isMobile && !isSearch && !isLoginPage" icon :color="headerColor" @click="toPage('Search')"> -->
        <v-icon v-if="isMobile && !isSearch && !isLoginPage" class="mr-2" color="#fff" @click="toPage('Search')" medium>mdi-magnify</v-icon>
      <!-- </v-btn> -->

      <!-- <v-btn v-if="isMobile && !isLoginPage" icon :color="headerColor" @click="toPage('Order')"> -->
        <v-icon v-if="isMobile && !isLoginPage" class="mr-2" color="#fff" @click="toPage('Order')" medium>mdi-calendar-text</v-icon>
      <!-- </v-btn> -->

      <span v-if="!isMobile" class="header-text-w">
        <!-- <span v-if="!isLogin" @click="toPage('Login')" :style="{ color: headerColor }"> 
          {{ $t('login') }} 
        </span> -->
        <span class="uppercase" v-if="isLogin" @click="toPage('My-Account')" :style="{ color: headerColor }">
          {{ userName}}
        </span>
      </span>

      <span v-if="!isMobile" class="header-text-w" @click="toPage('Search')" :style="{ color: headerColor }">
        {{ $t('search') }}
      </span>

      <span v-if="!isMobile" class="header-text-w uppercase mr-2" @click="toPage('Order')" :style="{ color: headerColor }">
        {{ $t('account.purchase.title') }}
      </span>
      
      <v-badge
        color="black"
        :content="cartNumber ? cartNumber : '0'"
        offset-x="15"
        offset-y="12"
      >
        <!-- <v-btn icon :color="headerColor" @click="toPage('Cart')"> -->
          <v-icon class="mr-2" color="#fff" @click="toPage('Cart')" medium>mdi-shopping-outline</v-icon>
        <!-- </v-btn> -->
      </v-badge>

    </v-app-bar>

    <div v-if="(isMobile && activePage === 'HOME') || !isMobile" class="text-center" @click="toPage('Home')">
      <img src="@/assets/logo2.png" class="img-logo" @click="toPage('Home')">
    </div>

    <div v-if="isMobile && !isHome" class="page-title">
      <span> {{ activePage}} </span>
    </div>
    
    <!-- <div class="search-section">
      <Search />
    </div> -->
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  // import Search from '@/components/Search'

  export default {
    name: 'Header',
    // components: { Search },
    computed: {
      ...mapState([
        'isSearch',
        'isHome',
        'isMobile',
        'isLoginPage',
        'isMemberPage',
        'activePage',
        'isLogin',
        'userName',
        'cartNumber',
        'headerColor',
        'bgColor'
      ]),
      searchTextClass () {
        return {
          color: this.headerColor,
        }
      }
    },
    methods: {
      toPage (name) {
        if (this.$route.name !== name) {
          this.$router.push({ name: name })
        }
      },
      showHideMenu () {
        this.$emit('showHide')
      }
    }
  }
</script>

<style scoped>
  .appbar-custom >>> .v-toolbar__content {
    padding: 12px 6px !important;
  }

  .header-text-w  {
    color: #FFFFFF;
    margin-left: 20px;
    font-size: 12px !important;
    cursor: pointer;
  }

  .search-text-w {
    /* color: #FFFFFF; */
    margin-right: 10px;
    font-size: 22px !important;
    font-weight: 800;
  }

  .search-text {
    /* color: #000000; */
    margin-right: 10px;
    font-size: 22px !important;
    font-weight: 800;
  }

  .page-title {
    text-transform: uppercase;
    position: fixed;
    left: 50%;
    top: 20px;
    transform: translate(-50%, 0);
    z-index: 20;
    color: #fff;
    font-size: 0.8rem !important;
    font-weight: 700;
  }

  .mr-100 {
    margin-right: 300px;
  }

  .line-w {
    width: 150px;
    border: 1px solid #FFFFFF;
  }

  .line-b {
    width: 150px;
    border: 1px solid #000000;
  }

  .text-center {
    position: fixed;
    top: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 20;
  }

  .inline {
    display: inline-block;
  }

  .img-logo {
    width: 70%;
    max-width: 50px;
    cursor: pointer;
  }

  .uppercase {
    text-transform: uppercase;
  }

  /* .search-section {
    text-transform: uppercase;
    position: fixed;
    left: 50%;
    top: -10px;
    transform: translate(-50%, 0);
    z-index: 20;
    width:100%;
  } */
</style>
