<template>
  <div class="welcome" :style="{ 'background-color' : bgColor}">
    <!-- <div class="center"> -->
      <img src="@/assets/logo2.png" alt="logo" class="w-logo mb-16 mt-8">
    <!-- </div> -->
    <!-- <div class="center-content"> -->
      <v-carousel
        v-model="index"
        width="100%"
        height="auto" 
        :hide-delimiter-background="true" 
        :hide-delimiters="true" 
        :show-arrows="false"
      >
        <v-carousel-item>
          <p class="w-title" align="center">Order via QR Code</p>
          <p class="w-sub" align="center">It’s simple way to order our product no need to carry much product </p>
        </v-carousel-item>
        <v-carousel-item>
          <p class="w-title" align="center">Pay Online</p>
          <p class="w-sub" align="center">Never worry about change, pay exact amount while placing order </p>
        </v-carousel-item>
        <v-carousel-item>
          <p class="w-title" align="center">Skip Queues</p>
          <p class="w-sub" align="center">Avoid standing in queues, we will call you loudly while order is ready </p>
        </v-carousel-item>
      </v-carousel>
    <!-- </div> -->
    <v-btn
      v-if="index > 0"
      class="left"
      fab
      color="#fff"
      @click="prev"
    >
    <v-icon x-large>
        mdi-chevron-left
      </v-icon>
    </v-btn>

    <v-btn
      v-if="index < 2"
      class="right"
      fab
      color="#fff"
      @click="next"
    >
      <v-icon x-large>
        mdi-chevron-right
      </v-icon>
    </v-btn>

    <v-btn
      v-if="index === 2"
      class="right"
      color="#fff"
      height="54"
      @click="$emit('close')"
    >
      <span class="w-button text-capitalize" :style="{ 'color' : bgColor}"> Get Started</span>
    </v-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Welcome',
  computed: {
    ...mapState([
      'bgColor'
    ])
  },
  data () {
    return {
      index: 0
    }
  },
  methods: {
    next () {
      if (this.index < 2) {
        this.index += 1
      }
    },
    prev () {
      if (this.index > 0) {
        this.index -= 1
      }
    }
  }
}
</script>

<style scoped>
.welcome {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 999;
  overflow-y: auto;
  text-align: center !important;
  padding: 24px;
}

.center {
  /* position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%, 0); */
  text-align: center;
  width: 200px;
  padding: 20px;
}

.center-content {
  /* position: absolute;
  left: 50%;
  top: calc(20% + 200px);
  transform: translate(-50%, 0); */
  text-align: center;
  width: 85%;
  padding: 20px;
}

.left {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.right {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.w-logo {
  width: 100%;
  max-width: 200px;
  height: auto;
}

.w-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #fff;
}

.w-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
} 

.w-button {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}
</style>