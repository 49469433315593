<template>
  <div class="sidebar">
    <div class="sidebar-backdrop" v-if="isPanelOpen"></div>
    <transition name="slide">
      <div v-if="isPanelOpen" :class="isMobile ? 'sidebar-panel-m' : 'sidebar-panel'" :style="{'background-color' : bgColor}" @mouseleave="closeSidebarPanel"> 
        <v-app-bar class="appbar-custom" app color="#8B1724" elevation="0">
          <img src="@/assets/logo2.png" width="24px">
          <h1 class="s-title ml-2"> PREPP STUDIO</h1>
          <v-spacer></v-spacer>
          <v-btn v-if="isMobile" icon color="#fff" @click="closeSidebarPanel" class="pull-right">
            <v-icon color="#fff" large>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <!-- <div class="mb-10">
          <v-btn v-if="isMobile" icon color="#fff" @click="closeSidebarPanel" class="pull-right">
            <v-icon color="#fff" medium>mdi-close</v-icon>
          </v-btn>
        </div> -->
        <div class="sidebar-menu">
          <slot></slot>
        </div>
        <div class="bottom-menu" v-if="isMobile">
          <span class="text" @click="toPage('Home')"> {{ $t('main-page') }} </span>
          <span class="text pull-right"> 
            <span v-if="!isLogin" @click="toPage('Login')"> {{ $t('my-account') }} </span>
            <span v-if="isLogin" @click="toPage('My-Account')"> {{ userName }} </span> 
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Sidebar',
    computed: {
      ...mapState([
        'isMobile',
        'isLogin',
        'userName',
        'bgColor'
      ])
    },
    props: {
      isPanelOpen: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      closeSidebarPanel() {
        this.$emit('showHide')
      },
      toPage (name) {
        this.closeSidebarPanel()
        if (this.$route.name !== name) {
          this.$router.push({ name: name })
        }
      }
    }
  }
</script>

<style scoped>
  .appbar-custom >>> .v-toolbar__content {
    padding: 40px 12px !important;
  }

  .slide-enter-active,
  .slide-leave-active
  {
      transition: transform 500ms ease;
  }

  .slide-enter,
  .slide-leave-to {
      transform: translateX(-100%);
      transition: all 500ms ease-in 0s
  }

  .sidebar-backdrop {
    background-color:white;
    opacity: 0.5;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .sidebar-panel {
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 999;
    padding: 1rem 20px 2rem 20px;
    width: 320px;
  }

  .sidebar-panel-m{
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 999;
    padding: 1rem 20px 2rem 20px;
    width: 100%;
  }

  .sidebar-menu {
    overflow-y: auto;
    height: 80vh;
    margin-top: 75px;
  }

  .pull-right {
    float: right;
  }

  .bottom-menu {
    bottom: 0;
    position: fixed;
    width: 90%;
    padding: 10px 0 !important; 
    margin-bottom: 20px;
    border-top: 2px solid #fff;
  }

  .text {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: -1px;
    cursor: pointer;
    text-transform: uppercase;
    color: #fff;
  }

  .s-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    text-align: justify;
    color: #FFFFFF
  }

</style>
